<template>
  <div class="page page--terms terms">
    <h1 id="terms">{{$t('pricing.title')}}</h1>
    <div v-html="$t('pricing.text')"></div>
  </div>
</template>

<script>
export default {
  name: "Pricing",
  mounted () {
    window.scrollTo(0, 0);
  }
}
</script>